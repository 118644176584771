import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "personal-center common-el-wrap" }
const _hoisted_2 = {
  key: 0,
  class: "personal-center-card"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BackgroundImg = _resolveComponent("BackgroundImg")!
  const _component_PersonalRaceInfo = _resolveComponent("PersonalRaceInfo")!
  const _component_RaceTeam = _resolveComponent("RaceTeam")!
  const _component_WorkList = _resolveComponent("WorkList")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_BackgroundImg),
    _createVNode(_component_PersonalRaceInfo, {
      raceInfo: _ctx.raceInfo,
      teamInfo: _ctx.teamInfo,
      userStatus: _ctx.userStatus
    }, null, 8, ["raceInfo", "teamInfo", "userStatus"]),
    (_ctx.raceInfo?.hasModify || _ctx.raceInfo?.hasAdd || _ctx.userStatus?.hasRegisterCom)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode(_component_RaceTeam, {
            raceInfo: _ctx.raceInfo,
            teamInfo: _ctx.teamInfo,
            userStatus: _ctx.userStatus
          }, null, 8, ["raceInfo", "teamInfo", "userStatus"]),
          (_ctx.raceInfo?.LifeCycle > 3 && _ctx.userStatus?.hasRegisterCom)
            ? (_openBlock(), _createBlock(_component_WorkList, { key: 0 }))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true)
  ]))
}