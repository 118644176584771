
import { Options, Vue } from 'vue-class-component';
import { useStore, Store } from 'vuex';
import { useRouter, Router } from 'vue-router';
import { findIndex, intersectionBy, sortBy, groupBy } from 'lodash';
import { ITeamInfo } from '@/interface/userInfo';
import { ElTabs, ElTabPane, ElButton, ElLink } from 'element-plus';
import { queryCountArRank } from '@/api';
import { channelInfo, IRaceInfo } from '@/interface/raceInfo';
import moment from 'moment';
import SemiFailed from '@/components/semiFailed.vue';

import { stepMap } from '@/utils/channelMap';

@Options({
    props: {
        userStatus: Object,
        raceInfo: <IRaceInfo>{},
        teamInfo: <ITeamInfo>{},
    },
    components: {
        Tabs: ElTabs,
        TabPane: ElTabPane,
        Button: ElButton,
        Link: ElLink,
        SemiFailed,
    },
})
export default class PersonalRaceInfo extends Vue {
    private router: Router = useRouter();
    private store: Store<any> = useStore();
    private stepMap = stepMap;
    userStatus!: Object;
    raceInfo!: IRaceInfo;
    teamInfo!: ITeamInfo;
    private activeTab = '0';
    private activeTabSemi = '0';
    get isTransition() {
        const { CurTime, PreEnd, SemiStart, SemiEnd, FinalStart, FinalEnd } = this.raceInfo;
        return (CurTime > PreEnd && CurTime < SemiStart) || (CurTime > SemiEnd && CurTime < FinalStart) || CurTime > FinalEnd;
    }
    get raceStepText() {
        return this.raceInfo?.LifeCycle === 7 ? '初赛' : '复赛';
    }
    get isInContest() {
        return (
            this.raceInfo?.LifeCycle === 4 ||
            this.raceInfo?.LifeCycle === 6 ||
            (this.teamInfo?.StepState === 3 && this.raceInfo?.LifeCycle === 8) ||
            (this.teamInfo?.StepState === 5 && this.raceInfo?.LifeCycle === 16)
        );
    }
    get isInFInal() {
        if (this.raceInfo?.sortTimes) {
            const index = findIndex(this.raceInfo?.sortTimes, v => v.name === 'CurTime');
            return index > 6;
        }
        return false;
    }
    get preStartTime() {
        if (this.raceInfo?.PreStart) {
            return moment(this.raceInfo?.PreStart).format('YYYY-MM-DD HH:mm');
        }
        return '';
    }
    get channelInfo(): channelInfo[] {
        return this.store.state.channelInfo;
    }
    get preChannels(): channelInfo[] {
        if (this.channelInfo && this.teamInfo?.Channels) {
            return intersectionBy(this.channelInfo, this.teamInfo?.Channels, 'Id');
        }
        return [];
    }
    get semiChannels(): channelInfo[] {
        if (this.channelInfo && this.teamInfo?.Channels) {
            return intersectionBy(this.channelInfo, this.teamInfo?.SemiChannels, 'Id');
        }
        return [];
    }
    get raceChannels(): channelInfo[] {
        if (this.channelInfo && this.teamInfo?.Channels) {
            return intersectionBy(this.channelInfo, this.teamInfo?.RaceChannels, 'Id');
        }
        return [];
    }
    private preRanks = {};
    private semiRanks = {};
    private async getCountArRank() {
        const preConditions = [
            {
                Field: 'RaceId',
                Opt: 0,
                Value: this.raceInfo?.Id,
            },
            {
                Field: 'Step',
                Opt: 0,
                Value: '1',
            },
        ];
        const { Code, Data } = await queryCountArRank(preConditions);
        if (Code) return;
        this.preRanks = groupBy(sortBy(Data, 'Step'), 'ChannelId');
    }
    private async getCountArRankSemi() {
        const semiConditions = [
            {
                Field: 'RaceId',
                Opt: 0,
                Value: this.raceInfo?.Id,
            },
            {
                Field: 'Step',
                Opt: 0,
                Value: '2',
            },
        ];
        const { Code, Data } = await queryCountArRank(semiConditions);
        if (Code) return;
        this.semiRanks = groupBy(sortBy(Data, 'Step'), 'ChannelId');
    }
    private goToCenter() {
        this.router.replace('./personalCenter');
    }
    mounted(): void {
        if (this.raceInfo?.Id) {
            this.getCountArRank();
            this.getCountArRankSemi();
            return;
        }
        this.$watch('raceInfo', (value: IRaceInfo) => {
            if (value?.Id) {
                this.getCountArRank();
                this.getCountArRankSemi();
            }
        });
    }
}
