
import { Options, Vue } from 'vue-class-component';
import Clipboard from 'clipboard';

@Options({
    props: {
        copyContent: String,
    },
})
export default class ClipboardCom extends Vue {
    copyContent!: string;

    public clickCopy() {
        const clipboard = new Clipboard('#copyBtn');
        clipboard.on('success', () => {
            console.log('复制成功');
            clipboard.destroy();
        });
        clipboard.on('error', () => {
            console.log('复制失败');
            clipboard.destroy();
        });
    }
}
