export const feedbackQues = new Map([
    ['1', ['您是通过什么渠道了解到本次比赛的？', ['自己看到比赛宣传稿件', '通过朋友/同行线上分享', '通过朋友/同行线下推荐']]],
    ['2', ['您认为本次赛题难度设置如何?', ['难度过低', '难度适中', '难度过高']]],
    ['3', ['您本次没有提交作品的原因是?', ['赛题难度过大', '比赛流程复杂，不易操作', '没有时间参赛']]],
    ['4', ['对于比赛有没有更多的建议?']],
]);

export const anwserMap = new Map([
    ['A', '1'],
    ['B', '2'],
    ['C', '3'],
]);

export const anwserMapTrans = new Map([
    ['1', 'A'],
    ['2', 'B'],
    ['3', 'C'],
]);

export const channelGroupEr = new Map([
    ['赛道一', 'https://static.wecity.qq.com/h5/2021-11/赛道一-e7faa4e1feff604f98798dcca924adba.png'],
    ['赛道二', 'https://static.wecity.qq.com/h5/2021-11/赛道二-d7f3c230116c7f56ef8c2e3493c6f6bf.png'],
    ['赛道三', 'https://static.wecity.qq.com/h5/2021-11/赛道三-7f980223511a12a0706d77dd8388f83b.png'],
    ['赛道四', 'https://static.wecity.qq.com/h5/2021-11/赛道四-5da27b16d71e96b039d20b67ce2bab56.png'],
    ['赛道五', 'https://static.wecity.qq.com/h5/2021-11/赛道五-d5e851b37d3d4d2170cb51c879fbd015.png'],
]);
