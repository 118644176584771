
import { Vue, Options } from 'vue-class-component';
import { ElForm, ElFormItem, ElInput, ElButton, ElMessage, ElRadioGroup, ElRadio } from 'element-plus';
import { validateForm, validatePhone } from '@/utils/validate';
import { feedbackQues, anwserMap, anwserMapTrans, channelGroupEr } from '@/utils/userFeedback';
import { updatePostInfo, saveArFeedback, queryArFeedback } from '@/api/index';
import { IUserPersonalInfo, IPostInfos } from '@/interface/userInfo';
import { channelInfo } from '@/interface/raceInfo';
import { find, last } from 'lodash';

@Options({
    props: {
        userStatus: <IUserPersonalInfo>{},
        stepState: Number,
        step: Number,
        raceId: String,
        raceChannels: Array,
        raceOrders: Array,
        curTime: String,
    },
    components: {
        Form: ElForm,
        FormItem: ElFormItem,
        Input: ElInput,
        Button: ElButton,
        RadioGroup: ElRadioGroup,
        Radio: ElRadio,
    },
})
export default class SemiFailed extends Vue {
    userStatus!: IUserPersonalInfo;
    stepState!: Number;
    step!: Number;
    raceId!: String;
    raceChannels!: Array<channelInfo>;
    raceOrders!: Array<{ channelId: string; order: number }>;
    curTime!: String;
    get isFeedbackEndTime() {
        return this.curTime > '2021-11-25T18:00:00+08:00';
    }
    private feedbackQues = feedbackQues;
    private formValue: {
        Address: string;
        RecTel: string;
        RecName: string;
        Ques1: string;
        Ques2: string;
        Suggestion: string;
    } = {
        Address: '',
        RecTel: '',
        RecName: '',
        Ques1: '',
        Ques2: '',
        Suggestion: '',
    };
    private rules: object = {
        Ques1: {
            required: true,
            message: '请选择',
            trigger: ['blur', 'change'],
        },
        Ques2: {
            required: true,
            message: '请选择',
            trigger: ['blur', 'change'],
        },
        Address: {
            required: true,
            message: '请输入有效地址',
            trigger: ['blur', 'change'],
        },
        RecName: {
            required: true,
            message: '请输入真实姓名',
            trigger: ['blur', 'change'],
        },
        RecTel: [
            {
                validator: (rule: object, value: string, callback: Function) => {
                    const { error } = validatePhone(rule, value);
                    if (error) {
                        callback(new Error(error));
                        return;
                    }
                    callback();
                },
                trigger: ['blur', 'change'],
            },
        ],
    };
    private isDisabled = this.hasPostInfos;
    get channelGroupImg() {
        return this.raceChannels.map((item: any) => {
            return { name: item.CharName, src: channelGroupEr.get(item.CharName) };
        });
    }
    get finalRank() {
        const res: any = [];
        this.raceOrders.forEach((item: any) => {
            const name = find(this.raceChannels, { Id: item.ChannelId })?.CharName;
            if (name) {
                res.push({ name, rank: item.Order });
            }
            // return { name: find(this.raceChannels, { Id: item.ChannelId })?.CharName, rank: item.Order };
        });
        return res;
    }
    get isAttendFinal() {
        return this.finalRank.length;
    }
    get hasPostInfos() {
        return !!this.userStatus?.PostInfos?.length;
    }
    private leftText = this.hasPostInfos ? '提交' : '确定';
    async savePostInfo() {
        const { Address, RecTel, RecName } = this.formValue;
        const data = { Id: this.userStatus?.Id, PostInfos: [{ Address, RecTel, RecName }] };
        const { Code, Data } = await updatePostInfo(data);
        if (Code) {
            return ElMessage.error('提交信息失败');
        }
        ElMessage.success('提交信息成功');
    }
    async submitForm() {
        validateForm(<InstanceType<typeof ElForm>>this.$refs?.addressForm)
            .then(value => {
                if (value) {
                    if (this.stepState > 1) {
                        this.savePostInfo();
                    }
                    this.saveFeedback();
                    this.isDisabled = true;
                }
            })
            .catch(e => {
                console.log('not ok');
            });
    }
    async saveFeedback() {
        const res = [
            { Item: '1', Res: anwserMapTrans.get(this.formValue.Ques1) },
            { Item: this.stepState === 1 ? '3' : '2', Res: anwserMapTrans.get(this.formValue.Ques2) },
            { Item: '4', Res: this.formValue.Suggestion },
        ];
        const { Code, Data } = await saveArFeedback({ RaceId: this.raceId, Step: this.step, Result: res });
        if (this.stepState === 1 && Code) {
            return ElMessage.error('提交反馈失败');
        }
        if (this.stepState === 1) {
            ElMessage.success('提交反馈成功');
        }
    }
    resetForm() {
        this.isDisabled = false;
    }
    async getArFeedback() {
        const { Code, Data } = await queryArFeedback();
        if (Code) {
            return;
        }
        if (Data[0]?.Result?.length) {
            this.isDisabled = true;
            const feedbackQues = Data[0].Result;
            this.formValue.Ques1 = anwserMap.get(find(feedbackQues, ['Item', '1'])?.Res) || '';
            this.formValue.Ques2 =
                this.stepState === 1
                    ? anwserMap.get(find(feedbackQues, ['Item', '3'])?.Res) || ''
                    : anwserMap.get(find(feedbackQues, ['Item', '2'])?.Res) || '';
            this.formValue.Suggestion = find(feedbackQues, ['Item', '4'])?.Res || '';
        }
    }
    async mounted() {
        this.getArFeedback();
        this.$watch('hasPostInfos', (value: boolean) => {
            this.isDisabled = value;
        });
        this.$watch(
            'userStatus',
            (value: any) => {
                if (value.PostInfos?.length) {
                    this.formValue = { ...this.formValue, ...value.PostInfos[0] };
                }
            },
            { immediate: true }
        );
    }
}
