
import { Options, Vue } from 'vue-class-component';
import UserInfo from '@/components/userInfo.vue';
import GrayHeader from '@/components/grayHeader.vue';
import BackgroundImg from '@/components/backgroundImg.vue';
import PersonalRaceInfo from '@/components/personalRaceInfo.vue';
// import RaceTeam from '@/components/raceTeam.vue';
import WorkList from '@/components/workList.vue';
import RaceTeam from '@/components/raceTeam.vue';
import { useStore, Store } from 'vuex';
import { useRouter, Router } from 'vue-router';
import { IUserPersonalInfo } from '@/interface/userInfo';

@Options({
    components: {
        UserInfo,
        GrayHeader,
        BackgroundImg,
        WorkList,
        RaceTeam,
        PersonalRaceInfo,
    },
})
export default class PersonalCenter extends Vue {
    private store: Store<any> = useStore();
    public router: Router = useRouter();
    get userStatus(): IUserPersonalInfo {
        const { AuditState: TeamAuditState, Id: TeamId, Channels } = this.store.state.teamInfo || {};
        const { AuditState: UserAuditState } = this.store.state.userStatus || {};
        let hasRegisterCom = false;
        if (UserAuditState === 3 && TeamAuditState === 3 && TeamId && Channels?.length) {
            hasRegisterCom = true;
        }
        return { ...(this.store.state.userStatus || {}), hasRegisterCom };
    }
    get raceInfo(): Object {
        return this.store.state.raceInfo;
    }
    get teamInfo(): Object {
        return this.store.state.teamInfo;
    }
    private goPage() {
        this.router.push('./');
    }
    async mounted() {
        this.store.dispatch('getTeamInfo');
    }
}
