
import { Options, Vue } from 'vue-class-component';
import { useStore, Store } from 'vuex';
import { queryArRecord } from '@/api';
import { ElPagination, ElPopover } from 'element-plus';
import { worksMap, workStatusMap, stepMap } from '@/utils/channelMap';
import moment from 'moment';

@Options({
    props: {
        ChannelId: String,
        Step: String,
    },
    components: {
        Pagination: ElPagination,
        Popover: ElPopover,
    },
})
export default class WorkListTable extends Vue {
    private store: Store<any> = useStore();
    ChannelId!: string;
    Step!: string;
    private worksMap = worksMap;
    private workStatusMap = workStatusMap;
    private stepMap = stepMap;
    private currentPage = 1;
    private totalPage = 0;
    private workers = [];
    private pageSize = 5;
    get userStatus(): Object {
        return this.store.state.userStatus;
    }
    get raceInfo(): Object {
        return this.store.state.raceInfo;
    }
    get teamInfo(): Object {
        return this.store.state.teamInfo;
    }
    private momentTime(time: Date) {
        if (!time) return '';
        return moment(time).format('YYYY-MM-DD HH:mm');
    }
    private async getArRecord() {
        const Conditions = [
            {
                Field: 'ChannelId',
                Opt: 0,
                Value: this.ChannelId,
            },
            {
                Field: 'Step',
                Opt: 0,
                Value: this.Step,
            },
        ];
        const Page = {
            CurPage: this.currentPage,
            PageSize: this.pageSize,
        };
        const { Code, Data } = await queryArRecord({ Page, Conditions });
        if (Code) return;
        const { Data: listData, Page: listPage } = Data;
        const { CurPage, TotalCount } = listPage;
        this.workers = listData;
        this.currentPage = CurPage;
        this.totalPage = TotalCount;
    }
    mounted(): void {
        this.getArRecord();
    }
    public changePage(current: number) {
        this.currentPage = current;
        this.getArRecord();
    }
}
