
import { Options, Vue } from 'vue-class-component';
import { useStore, Store } from 'vuex';
import { ITeamInfo, IUserPersonalInfo } from '@/interface/userInfo';
import { IRaceInfo, channelInfo } from '@/interface/raceInfo';
import { ElPopover, ElRadioGroup, ElRadio, ElMessage, ElButton, ElLink, ElMessageBox, ElInput } from 'element-plus';
import { queryTeamMember, saveArTeam, updateArTeam, deleteArTeam, updateArUserComb, saveArUserComb, getArTeam } from '@/api';
import { find } from 'lodash';
import moment from 'moment';
import { channelMap } from '@/utils/channelMap';
import ClipBoard from './clipboard.vue';

@Options({
    props: {
        userStatus: <IUserPersonalInfo>{},
        raceInfo: <IRaceInfo>{},
        teamInfo: <ITeamInfo>{},
    },
    components: {
        Popover: ElPopover,
        RadioGroup: ElRadioGroup,
        Radio: ElRadio,
        Button: ElButton,
        Link: ElLink,
        Input: ElInput,
        ClipBoard,
        ElMessage,
    },
})
export default class RaceTeam extends Vue {
    private store: Store<any> = useStore();
    userStatus!: IUserPersonalInfo;
    raceInfo!: IRaceInfo;
    teamInfo!: ITeamInfo;
    private teamMember = [];
    private teamAction = 'createTeam';
    private teamNameInput = {
        value: '',
        errMessage: '',
    };
    private orderMap = channelMap;
    private teamNameSearch = {
        value: '',
        errMessage: '',
        teamId: '',
        name: '',
    };
    private isCaption = false;
    get regEndTime() {
        const RegEnd = this.raceInfo?.RegEnd;
        if (RegEnd) return moment(RegEnd).format('YYYY-MM-DD HH:mm');
        return '';
    }
    get preStartTime() {
        const preStart = this.raceInfo?.PreStart;
        if (preStart) return moment(preStart).format('YYYY-MM-DD HH:mm');
        return '';
    }
    get channelInfo(): Object {
        return this.store.state.channelInfo;
    }
    get isModifyChannel() {
        return this.teamInfo?.InviteNo && this.userStatus?.AuditState === 3 && this.isCaption;
    }
    get ChannelsList() {
        return this.teamInfo?.Channels?.map(v => v.Id) || [];
    }
    // 退出赛道
    private async exitChannel(item: channelInfo, index: number) {
        const { Id, Title } = item;
        if (!Id) return;
        ElMessageBox.confirm(
            `退出后队伍其他成员也将推出该赛道，是否确认退出${this.orderMap.get(index + 1)}（${Title}）？`,
            `退出${this.orderMap.get(index + 1)}`,
            {
                confirmButtonText: `确认退出`,
                cancelButtonText: '取消',
            }
        ).then(async () => {
            this.deleteChannels(Id);
        });
    }
    private async deleteChannels(Id: string) {
        const Channels = this.teamInfo.Channels.filter(v => v.Id !== Id);
        const { Code } = await updateArTeam({ Id: this.teamInfo?.Id, Channels });
        if (!Code) {
            ElMessage({
                showClose: true,
                message: '退出成功',
                type: 'success',
            });
            this.store.dispatch('getTeamInfo');
        }
    }
    // addChannels
    private async addChannels(item: channelInfo) {
        if (!item) return;
        const Channels = (this.teamInfo?.Channels || []).concat([{ Id: item.Id, Title: `${this.orderMap.get(item.Order)}：${item.Title}` }]);
        const { Code } = await updateArTeam({ Id: this.teamInfo?.Id, Channels });
        if (!Code) {
            ElMessage({
                showClose: true,
                message: '加入成功',
                type: 'success',
            });
            this.store.dispatch('getTeamInfo');
        }
    }
    // 获取组队成员
    private async getTeamMember() {
        const { Code, Data } = await queryTeamMember();
        if (!Code) {
            this.teamMember = Data;
            if (Data.length) {
                const currentUserRow = find(Data, v => v.Uin === this.userStatus?.Uin);
                if (currentUserRow?.Role === 2) {
                    this.isCaption = true;
                    return;
                }
            }
            this.isCaption = false;
        }
    }
    // 搜索队伍
    private async searchTeam() {
        const { value: Value } = this.teamNameSearch;
        if (!Value) return;
        const Conditions = [
            {
                Field: 'InviteNo',
                Opt: 0,
                Value,
            },
        ];
        const { Code, Message, Data } = await getArTeam(Conditions);
        if (Code) {
            this.teamNameSearch.errMessage = Message;
            return;
        }
        const { Id, AuditState, Name } = Data[0] || {};
        if (!Id) {
            this.teamNameSearch.errMessage = '队伍不存在，请确定队伍邀请码';
            return;
        }
        if (AuditState !== 3) {
            this.teamNameSearch.errMessage = '该队伍暂未通过审核';
            return;
        }
        this.teamNameSearch.teamId = Id;
        this.teamNameSearch.name = Name;
    }
    // 队员加入参加队伍
    private async joinArUserComb() {
        const { teamId: TeamId } = this.teamNameSearch;
        if (!TeamId) return;
        const { Code, Message, Data } = await saveArUserComb({ TeamId });
        if (Code) {
            ElMessageBox.alert(Message, '加入队伍失败', {
                confirmButtonText: '知道了',
            });
            this.teamNameSearch.errMessage = Message;
            return;
        }
        this.teamNameSearch.teamId = Data[0]?.TeamId;
        this.store.dispatch('getTeamInfo');
        this.getTeamMember();
    }
    // 解散队伍或者退队/移除队员 2  退队 3   移除队员
    private deleteTeam(TeamId: string, UserState?: string, Id?: string) {
        let action = '';
        if (!UserState) {
            action = '解散队伍';
        }
        if (UserState === '2') {
            action = '退出队伍';
        }
        let message = `${action}后需重新创建/加入队伍才可进行比赛，是否确认${action}该队?`;
        if (UserState === '3') {
            action = '移除成员';
            message = '是否确认移除该成员？';
        }
        ElMessageBox.confirm(message, action, {
            confirmButtonText: `确认${action.slice(0, 2)}`,
            cancelButtonText: '取消',
        }).then(async () => {
            this.deleteTeamHttp(TeamId, UserState, Id);
        });
    }
    private async deleteTeamHttp(TeamId: string, UserState?: string, Id?: string) {
        const method = Id ? updateArUserComb : deleteArTeam;
        const { Code } = await method({
            Id,
            TeamId,
            UserState,
        });
        if (!Code) {
            const message = UserState === '3' ? '移除成功' : '退出成功';
            ElMessage({
                showClose: true,
                message,
                type: 'success',
            });
            // 刷新memberList / teamInfo
            this.getTeamMember();
            this.store.dispatch('getTeamInfo');
        }
    }
    // 队伍更新/新建
    private async handleSaveArTeam() {
        const { Id } = this.teamInfo || {};
        let { value: Name } = this.teamNameInput;
        Name = Name.trim();
        if (!Name) {
            this.teamNameInput.errMessage = '请输入队伍名称';
            return;
        }
        const method = Id ? updateArTeam : saveArTeam;
        const { Code } = await method({ Id, Name });
        if (!Code) {
            ElMessage({
                showClose: true,
                message: '新建成功',
                type: 'success',
            });
            this.store.dispatch('getTeamInfo');
            this.getTeamMember();
            this.teamNameInput.value = '';
        }
        if (Code === 'InvalidParameterValue') {
            this.teamNameInput.errMessage = '该队伍名称已存在，请重新输入';
        }
    }
    private handleChangeValue(obj: string) {
        if (obj === 'teamNameInput') {
            this.teamNameInput.errMessage = '';
            return;
        }
        this.teamNameSearch.errMessage = '';
        this.teamNameSearch.teamId = '';
        this.teamNameSearch.name = '';
    }
    mounted(): void {
        this.getTeamMember();
    }
}
