
import { Options, Vue } from 'vue-class-component';
import { useStore, Store } from 'vuex';
import { queryCountArRecord, submitWorks } from '@/api';
import { intersectionBy, find } from 'lodash';
import { ITeamInfo } from '@/interface/userInfo';
import { channelInfo, IRaceInfo } from '@/interface/raceInfo';
import { ElTooltip, ElButton, ElTabs, ElTabPane, ElPagination, ElInput } from 'element-plus';
import WorkListTable from './workListTable.vue';

@Options({
    props: {
        childInfo: Object,
    },
    components: {
        Tooltip: ElTooltip,
        Button: ElButton,
        Tabs: ElTabs,
        TabPane: ElTabPane,
        Input: ElInput,
        Pagination: ElPagination,
        WorkListTable,
    },
})
export default class WorkList extends Vue {
    private store: Store<any> = useStore();
    get userStatus(): Object {
        return this.store.state.userStatus;
    }
    get raceInfo(): IRaceInfo {
        return this.store.state.raceInfo;
    }
    get teamInfo(): ITeamInfo {
        return this.store.state.teamInfo;
    }
    get channelInfo(): channelInfo[] {
        return this.store.state.channelInfo;
    }
    get channels(): channelInfo[] {
        if (this.channelInfo && this.teamInfo?.Channels) {
            return intersectionBy(this.channelInfo, this.teamInfo?.Channels, 'Id');
        }
        return [];
    }
    get canSubmit() {
        // 复赛开始之前的阶段 || 初赛淘汰的选手
        if (this.raceInfo?.LifeCycle < 8 || (this.teamInfo?.StepState < 3 && this.raceInfo?.LifeCycle >= 8)) {
            return find(this.teamInfo?.Channels, { Id: this.curChannel?.Id }) !== undefined;
        }
        // 进入复赛 || 决赛开始之前
        if (this.teamInfo?.StepState < 5 && this.raceInfo?.LifeCycle < 16) {
            return find(this.teamInfo?.SemiChannels, { Id: this.curChannel?.Id }) !== undefined;
        }
        return false;
    }
    get curChannel(): channelInfo {
        const index = Number(this.activeName);
        return this.channels[index] || {};
    }
    get isInContest() {
        return (
            this.raceInfo?.LifeCycle === 4 ||
            this.raceInfo?.LifeCycle === 6 ||
            (this.teamInfo?.StepState === 3 && this.raceInfo?.LifeCycle === 8) ||
            (this.teamInfo?.StepState === 5 && this.raceInfo?.LifeCycle === 16)
        );
    }
    private activeName = '0';
    private upTimes = 0;
    private handleTab() {
        this.getTimes();
    }
    get step(): string[] {
        const index = this.raceInfo?.LifeCycle;
        const state = this.teamInfo?.StepState;
        if (index > 15 && state > 4) {
            return ['1', '2', '3'];
        }
        if (index > 7 && state > 2) {
            return ['1', '2'];
        }
        if (index > 3) {
            return ['1'];
        }
        return [];
    }
    private isRaceTime = [4, 6, 8, 16, 32];
    private async getTimes() {
        if (!this.curChannel?.Id) return;
        const Conditions = [
            {
                Field: 'ChannelId',
                Opt: 0,
                Value: this.curChannel?.Id,
            },
        ];
        const { Code, Data } = await queryCountArRecord({ Conditions });
        if (Code) return;
        this.upTimes = Data < 0 ? 0 : Data;
    }
    private async submitWorks(ResultType: number) {
        if (!ResultType || !this.curChannel?.Id) return;
        const ChannelId = this.curChannel?.Id;
        const Step = this.step.length;
        const { Code } = await submitWorks({ ResultType, ChannelId, Step });
        if (Code) return;
        this.getTimes();
        for (let i = 1; i < this.step.length + 1; i++) {
            const tab = this.$refs[`tablelist${this.activeName}${i}`] as WorkListTable;
            if (tab) {
                tab.changePage(1);
            }
        }
    }
    private flag = true;
    mounted(): void {
        if (this.curChannel?.Id) {
            this.handleTab();
            return;
        }
        this.$watch('curChannel', (value: channelInfo) => {
            if (value?.Id && this.flag) {
                this.flag = false;
                this.handleTab();
            }
        });
    }
}
